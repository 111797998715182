<template>
    <button class="button-link text-white -mt-4 ml-4 hover:text-white focus:ring-offset-gray-900"
            @click.stop="toggleMenu">Menu</button>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
    computed: {
        ...mapState('adminMenu', {
            open: 'mobileMenuIsOpen',
        }),
    },

    mounted () {
        this.$nextTick(() => {
            window.addEventListener('resize', this.windowResize);
        });
    },

    methods: {
        ...mapActions('adminMenu', [
            'openMobileMenu',
            'closeMobileMenu',
        ]),

        toggleMenu () {
            if (this.open) {
                this.closeMobileMenu();
            } else {
                this.openMobileMenu();
                document.documentElement.addEventListener('click', this.htmlClick);
            }
        },
        windowResize () {
            this.closeMobileMenu();
        },

        htmlClick () {
            this.closeMobileMenu();
            document.documentElement.removeEventListener('click', this.htmlClick);
        },
    },
}
</script>
