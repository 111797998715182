var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    {
      staticClass:
        "flex justify-between items-center w-full py-2 pr-2 pl-4 mb-1 font-light",
    },
    [
      _c(
        "span",
        {
          staticClass:
            "flex flex-wrap justify-start items-center w-full lg:text-base",
        },
        [
          _c("admin-icon", {
            staticClass: "w-5 h-5 mr-3 mb-0",
            attrs: { icon: _vm.icon, width: "20", height: "20" },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "leading-none" }, [
            _vm._v(_vm._s(_vm.label)),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasSubnav
        ? _c("admin-icon", {
            staticClass: "w-3 h-3",
            attrs: { icon: "gt", width: "12", height: "12" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }