<template>
    <a :href="phone|formatPhone('RFC3966')">{{ phone|formatPhone }}</a>
</template>

<script>
export default {
    props: {
        phone: {
            type: Object,
            required: true,
        },
    },
};
</script>
