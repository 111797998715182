<template>
    <admin-icon :icon="icon"
                :class="iconClasses"
                class="w-5 h-5 fill-current"
                width="20"
                height="20" />
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        clickable: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        icon () {
            return this.value ? 'check' : 'x';
        },

        iconClasses () {
            if (this.value) {
                return {
                    'text-green-600': true,
                    'hover:text-green-500 transition': this.clickable,
                };
            }

            return {
                'text-red-600': true,
                'hover:text-red-500 transition': this.clickable,
            };
        },
    },
};
</script>
