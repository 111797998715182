var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ready
    ? _c(
        "div",
        { staticClass: "bg-gray-900" },
        [
          _c(
            "nav",
            {
              staticClass: "sidebar_nav-wrap",
              attrs: { role: "navigation", "aria-label": "Main" },
            },
            [
              _c(
                "router-link",
                {
                  staticClass:
                    "flex items-center w-40 lg:w-64 border-b border-gray-700 focus:ring-offset-gray-900 rounded-none",
                  staticStyle: { height: "3.75rem", padding: "0.65rem 0" },
                  attrs: { to: _vm.logoLinkRoute },
                },
                [
                  _c("img", {
                    staticClass: "h-10 ml-4",
                    attrs: {
                      src: "/images/logo.svg",
                      width: "60",
                      height: "25",
                      alt: "FLC Seniors",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "sidebar_nav-nav" },
                [
                  _vm.loggedIn
                    ? [
                        _c(
                          "li",
                          { staticClass: "mb-2 lg:mb-0" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar_nav-link",
                                attrs: {
                                  to: _vm.$store.getters.memberListRoute,
                                },
                              },
                              [
                                _c("menu-link", {
                                  attrs: { label: "Members", icon: "users" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          { staticClass: "mb-2 lg:mb-0" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar_nav-link",
                                attrs: { to: { name: "admin-event" } },
                              },
                              [
                                _c("menu-link", {
                                  attrs: { label: "Events", icon: "calendar" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.hasRole("ROLE_ADMIN")
                          ? _c(
                              "li",
                              { staticClass: "mb-2 lg:mb-0" },
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "sidebar_nav-link",
                                    attrs: { to: { name: "admin-cart" } },
                                  },
                                  [
                                    _c("menu-link", {
                                      attrs: {
                                        label: "Purchases",
                                        icon: "purchases",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "li",
                          { staticClass: "mb-2 lg:mb-0" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar_nav-link",
                                attrs: {
                                  to: { name: "admin-activity-import" },
                                },
                              },
                              [
                                _c("menu-link", {
                                  attrs: { label: "Import", icon: "import" },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.hasRole("ROLE_ADMIN")
                          ? _c(
                              "li",
                              [
                                _c("menu-subnav", {
                                  attrs: {
                                    items: _vm.adminMenuItems,
                                    label: "Admin",
                                    icon: "gear",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("li", { staticClass: "mb-2 lg:mb-0" }, [
                          _c(
                            "a",
                            {
                              staticClass: "sidebar_nav-link",
                              attrs: { href: "/public", target: "_blank" },
                            },
                            [
                              _c("menu-link", {
                                attrs: { label: "Public Pages", icon: "go" },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "absolute bottom-0 w-40 lg:w-64 pt-2 text-gray-300 font-extralight",
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex items-end justify-between px-4" },
                    [
                      _vm.loggedIn
                        ? _c(
                            "div",
                            {
                              staticClass: "w-3/5 lg:w-2/3 mb-2 text-sm",
                              staticStyle: { "overflow-wrap": "break-word" },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "sidebar_nav-bottom_links",
                                  attrs: { to: { name: "user-profile-edit" } },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.profileLinkText) +
                                      "\n                    "
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.loggedIn
                        ? _c("div", { staticClass: "pb-2 pl-4 text-xs" }, [
                            _c(
                              "a",
                              {
                                staticClass:
                                  "sidebar_nav-bottom_links whitespace-nowrap",
                                attrs: { href: "/logout" },
                              },
                              [_vm._v("Sign Out")]
                            ),
                          ])
                        : _vm._e(),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "py-2 pl-4 text-xs text-gray-400 border-t border-gray-600",
                    },
                    [
                      _vm._v(
                        "\n                ©" +
                          _vm._s(_vm.copyrightYear) +
                          " FLC Seniors Club\n            "
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "header",
            { staticClass: "header-wrap-small" },
            [
              _vm.loggedIn ? _c("menu-small") : _vm._e(),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass:
                    "ml-auto rounded-none focus:ring-offset-gray-900",
                  attrs: { to: _vm.logoLinkRoute },
                },
                [
                  _c("img", {
                    staticClass: "h-10 -mt-4 mr-4",
                    attrs: {
                      src: "/images/logo.svg",
                      width: "80",
                      height: "33",
                      alt: "FLC Seniors",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "content-wrap js-content-wrap" }, [
            _vm.showDevWarning
              ? _c("div", { staticClass: "dev_warning" }, [
                  _c("span", { staticClass: "font-bold" }, [
                    _vm._v("Development & training site"),
                  ]),
                  _vm._v("\n            – use for testing only\n        "),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "header",
              { staticClass: "header-wrap" },
              [
                _c(
                  "h1",
                  { staticClass: "header-page_title" },
                  [
                    _c("portal-target", {
                      attrs: { name: "header-page-title" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("portal-target", {
                  staticClass: "header-actions",
                  attrs: { name: "header-actions" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("main", { staticClass: "p-4" }, [_c("router-view")], 1),
          ]),
          _vm._v(" "),
          _c("portal-target", { attrs: { name: "modal", multiple: "" } }),
        ],
        1
      )
    : _c("loading-spinner", { staticClass: "mt-8" })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }