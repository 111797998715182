var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass:
        "button-link text-white -mt-4 ml-4 hover:text-white focus:ring-offset-gray-900",
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.toggleMenu.apply(null, arguments)
        },
      },
    },
    [_vm._v("Menu")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }