import Vue from 'vue';
import Vuex from 'vuex';

import cloneDeep from 'lodash/cloneDeep';
import defaultsDeep from 'lodash/defaultsDeep';
import some from 'lodash/some';

import adminMenu from './menu/store';

Vue.use(Vuex);

const memberDefaultFilters = {
    q: null,
    qField: 'name',
    membershipStatus: {
        status: 'THIS_YEAR_AND_LAST',
        year: null,
    },
    gender: 'EITHER',
    activityYear: null,
    activityVolunteer: {
        status: null, // boolean
        activityYearId: null,
    },
    age: {
        equality: null,
        age: null,
        min: null,
        max: null,
    },
    deceased: false, // boolean
    sort: 'NAME',
    offset: 0,
};
const cartDefaultFilters = {
    // default start will be 31 days ago
    startDate: new Date(new Date().setDate(new Date().getDate() - 31)),
    endDate: null,
    status: null,
};

export default new Vuex.Store({
    namespaced: true,
    strict: process.env.NODE_ENV !== 'production',

    state: {
        ready: false,
        gMapsIsLoaded: false,
        user: null,
        isDev: process.env.NODE_ENV === 'development',

        availableRoles: {
            ROLE_USER: 'User',
            ROLE_ADMIN: 'Admin',
            ROLE_SUPER_ADMIN: 'Super Admin',
        },

        memberFilters: cloneDeep(memberDefaultFilters),
        cartFilters: cloneDeep(cartDefaultFilters),

        entrypointIntegrityHashes: {
            admin: null,
        },
    },

    getters: {
        loggedIn (state) {
            if (!state.ready) {
                return false;
            }

            return !!state.user;
        },

        hasRole: (state, getters) => (role) => {
            if (!getters.loggedIn) {
                return false;
            }

            // all logged in users have ROLE_USER
            if (role === 'ROLE_USER') {
                return true;
            }

            if (state.user.roles === null) {
                return false;
            }

            return state.user.roles.includes(role);
        },

        allowMemberEdit (state, getters) {
            return getters.hasRole('ROLE_ADMIN');
        },
        allowPrivateFields (state, getters) {
            return getters.hasRole('ROLE_ADMIN');
        },
        allowPastActivityYearEdit (state, getters) {
            return getters.hasRole('ROLE_ADMIN');
        },

        accessActivityYear: (state, getters) => (activityYearId) => {
            if (getters.hasRole('ROLE_ADMIN')) {
                return true;
            }

            for (let activity in state.user.activities) {
                if (some(state.user.activities[activity].activityYears, { activityYearId })) {
                    return true;
                }
            }

            return false;
        },

        /**
         * The filters that have been set/applied.
         */
        appliedMemberFilters (state) {
            const setFilters = {};

            for (const filter in state.memberFilters) {
                if (state.memberFilters[filter] !== null) {
                    switch (filter) {
                        case 'membershipStatus' :
                            setFilters.membershipStatus = {
                                // status will always be set (no null)
                                status: state.memberFilters.membershipStatus.status,
                            };
                            if (state.memberFilters.membershipStatus.year !== null) {
                                setFilters.membershipStatus.year = state.memberFilters.membershipStatus.year;
                            }
                            break;

                        case 'activityVolunteer' :
                            if (null !== state.memberFilters.activityVolunteer.status) {
                                setFilters.activityVolunteer = {
                                    status: state.memberFilters.activityVolunteer.status,
                                };

                                if (state.memberFilters.activityVolunteer.activityYearId) {
                                    setFilters.activityVolunteer.activityYearId =
                                        state.memberFilters.activityVolunteer.activityYearId;
                                }
                            }
                            break;

                        case 'age' :
                            if (state.memberFilters.age.equality === 'BETWEEN') {
                                if (state.memberFilters.age.min > 0 && state.memberFilters.age.max > 0) {
                                    setFilters.age = {
                                        equality: state.memberFilters.age.equality,
                                        min: +state.memberFilters.age.min,
                                        max: +state.memberFilters.age.max,
                                    };
                                }
                            } else if (state.memberFilters.age.age > 0) {
                                setFilters.age = {
                                    equality: state.memberFilters.age.equality,
                                    age: +state.memberFilters.age.age,
                                };
                            }
                            break;

                        default :
                            setFilters[filter] = state.memberFilters[filter];
                            break;
                    }
                }
            }

            return setFilters;
        },

        memberListRoute (state, getters) {
            return {
                name: 'admin-member',
                query: {
                    filters: getters.appliedMemberFilters,
                    offset: state.memberFilters.offset,
                },
            };
        },
    },

    actions: {
        updateUser ({ commit }, user) {
            commit('setUser', user);
        },

        updateMemberFilters ({ commit }, filters) {
            if ('' === filters.activityYear) {
                filters.activityYear = filters.activityYear = null;
            }
            if (typeof filters.deceased === 'string') {
                filters.deceased = filters.deceased === 'true';
            }
            if (filters.activityVolunteer && typeof filters.activityVolunteer.status === 'string') {
                filters.activityVolunteer.status = filters.activityVolunteer.status === 'true';
            }

            commit('setMemberFilters', defaultsDeep(filters, cloneDeep(memberDefaultFilters)));
        },
        updateMemberFilter ({ state, dispatch }, { filter, value }) {
            const filters = cloneDeep(state.memberFilters);
            filters[filter] = cloneDeep(value);

            dispatch('updateMemberFilters', filters);
        },
        resetMemberFilters ({ commit }) {
            commit('setMemberFilters', cloneDeep(memberDefaultFilters));
        },

        updateCartFilters ({ commit }, filters) {
            commit('setCartFilters', defaultsDeep(filters, cloneDeep(cartDefaultFilters)));
        },
        resetCartFilters ({ commit }) {
            commit('setCartFilters', cloneDeep(cartDefaultFilters));
        },

        setIntegrityHash ({ commit, state }, { entrypoint, hash }) {
            if (state.entrypointIntegrityHashes[entrypoint]) {
                // eslint-disable-next-line no-console
                console.error('Integrity hash already set for '+entrypoint+' entry point. Won\'t update.');
                return;
            }

            commit('setIntegrityHash', { entrypoint, hash });
        },
    },

    mutations: {
        ready (state) {
            state.ready = true;
        },
        gMapsIsLoaded (state) {
            state.gMapsIsLoaded = true;
        },
        setUser (state, user) {
            if (state.user === null) {
                Vue.set(state, 'user', { ...user });
            } else {
                Vue.set(state, 'user', { ...state.user, ...user });
            }
        },

        setMemberFilters (state, filters) {
            state.memberFilters = filters;
        },

        setCartFilters (state, filters) {
            state.cartFilters = filters;
        },

        setIntegrityHash (state, { entrypoint, hash }) {
            state.entrypointIntegrityHashes[entrypoint] = hash;
        },
    },

    modules: {
        adminMenu,
    },
});
