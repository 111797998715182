var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("admin-icon", {
    staticClass: "w-5 h-5 fill-current",
    class: _vm.iconClasses,
    attrs: { icon: _vm.icon, width: "20", height: "20" },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }