var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "field-wrap" },
    [
      _c(
        "label",
        { attrs: { for: _vm.id } },
        [
          _vm._t("default", function () {
            return [_vm._v("Password")]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("errors"),
      _vm._v(" "),
      _c("div", { staticClass: "relative z-20" }, [
        _c("input", {
          staticClass: "pr-10 mb-1",
          attrs: {
            id: _vm.id,
            type: _vm.fieldType,
            required: _vm.required,
            autocomplete: _vm.autocomplete,
            name: _vm.name,
            autocapitalize: "off",
            autocorrect: "off",
            spellcheck: "false",
          },
          domProps: { value: _vm.value },
          on: {
            input: function ($event) {
              return _vm.$emit("input", $event.target.value)
            },
            focus: function ($event) {
              _vm.showMeter = true
            },
          },
        }),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "absolute button-link block top-0 right-0 w-6 h-6 mr-2 text-gray-600 hover:text-gray-800 focus:ring-offset-gray-100",
            staticStyle: { "margin-top": "0.3rem" },
            attrs: { type: "button" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.visible = !_vm.visible
              },
            },
          },
          [
            _c(
              "svg",
              {
                staticClass: "w-6 h-6 fill-current",
                attrs: { width: "24", height: "24" },
              },
              [_c("use", { attrs: { "xlink:href": _vm.iconsPath + _vm.icon } })]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "sr-only" }, [_vm._v("Show password")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.showHelp && _vm.showMeter
        ? _c("password-score", {
            attrs: { password: _vm.value, "user-data": _vm.userData },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showHelp
        ? _c("div", { staticClass: "field-help relative" }, [
            _vm._v(
              "\n        Must be at least " +
                _vm._s(_vm.minLength) +
                " characters long.\n    "
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }