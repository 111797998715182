var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "flex justify-between w-full" }, [
    _c(
      "button",
      {
        staticClass: "button-link sidebar_nav-link mb-0",
        class: { "bg-gray-800": _vm.open },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.toggleMenu.apply(null, arguments)
          },
        },
      },
      [
        _c("menu-link", {
          attrs: { label: _vm.label, icon: _vm.icon, "has-subnav": true },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "submenu",
        staticClass: "sidebar_nav-submenu-wrap",
        class: { "sidebar_nav-submenu-wrap-open": _vm.open },
      },
      [
        _c("div", { staticClass: "sidebar_nav-submenu_header" }, [
          _vm._v(_vm._s(_vm.label)),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "h-full pt-2 list-none pl-0 overflow-y-scroll" },
          _vm._l(_vm.items, function (route, anchor) {
            return _c(
              "li",
              { key: route, staticClass: "mb-1" },
              [
                _c(
                  "router-link",
                  {
                    staticClass:
                      "sidebar_nav-link block py-2 px-4 hover:bg-blue-800 ring-offset-gray-800 focus:no-underline",
                    attrs: { to: { name: route } },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.subnavItemClicked.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(anchor) +
                        "\n                "
                    ),
                  ]
                ),
              ],
              1
            )
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }