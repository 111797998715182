import Vue from 'vue';
import Router from 'vue-router';
import store from './store';
import { scrollBehavior, parseQuery, stringifyQuery, logPageView } from '@/common/router_helpers';

import { RouteQuery } from './queries/user.query.graphql';

Vue.use(Router);

const router = new Router({
    mode: 'history',

    routes: [
        {
            name: 'login',
            path: '/login',
            component: () => import(/* webpackChunkName: "login" */ './login'),
        },
        {
            path: '/recover',
            component: () => import(/* webpackChunkName: "user-recover" */ './user_recover'),
            children: [
                { path: '', redirect: '/recover/initiate' },
                {
                    name: 'user-recover-initiate',
                    path: 'initiate',
                    component: () => import(/* webpackChunkName: "user-recover" */ './user_recover/initiate'),
                },
                {
                    name: 'user-recover-reset',
                    path: 'reset/:token',
                    component: () => import(/* webpackChunkName: "user-recover" */ './user_recover/reset'),
                },
            ],
        },
        {
            name: 'user-verify',
            path: '/activate/:token',
            component: () => import(/* webpackChunkName: "user-verify" */ './user_verify'),
        },
        {
            path: '/profile/edit',
            component: () => import(/* webpackChunkName: "user-profile-edit" */ './user_profile_edit'),
            children: [
                {
                    name: 'user-profile-edit',
                    path: '',
                    component: () => import(/* webpackChunkName: "user-profile-edit" */ './user_profile_edit/profile'),
                },
                {
                    name: 'user-profile-edit-password',
                    path: 'password',
                    component: () => import(/* webpackChunkName: "user-profile-edit" */ './user_profile_edit/password'),
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },

        {
            path: '/admin/member',
            component: () => import(/* webpackChunkName: "admin-member" */ './member'),
            children: [
                {
                    name: 'admin-member',
                    path: '',
                    component: () => import(/* webpackChunkName: "admin-member" */ './member/list'),
                },
                {
                    // this path is also in the PHP UrlGenerator
                    name: 'admin-member-view',
                    path: ':memberId/view',
                    component: () => import(/* webpackChunkName: "admin-member" */ './member/view'),
                    props: true,
                },
                {
                    name: 'admin-member-add',
                    path: 'add',
                    component: () => import(/* webpackChunkName: "admin-member" */ './member/add'),
                    meta: {
                        requiresAuth: true,
                        role: 'ROLE_ADMIN',
                    },
                },
                {
                    name: 'admin-member-edit',
                    path: ':memberId/edit',
                    component: () => import(/* webpackChunkName: "admin-member" */ './member/edit'),
                    props: true,
                    meta: {
                        requiresAuth: true,
                        role: 'ROLE_ADMIN',
                    },
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },
        {
            path: '/admin',
            redirect: { name: 'admin-member' },
        },

        {
            path: '/admin/cart',
            component: () => import(/* webpackChunkName: "admin-cart" */ './cart'),
            children: [
                {
                    name: 'admin-cart',
                    path: '',
                    component: () => import(/* webpackChunkName: "admin-cart" */ './cart/list'),
                },
                {
                    name: 'admin-cart-view',
                    path: ':cartId/view',
                    component: () => import(/* webpackChunkName: "admin-cart" */ './cart/view'),
                    props: true,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_ADMIN',
            },
        },

        {
            name: 'admin-activity-import',
            path: '/admin/activity-import',
            component: () => import(/* webpackChunkName: "admin-activity-import" */ './activity_import'),
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },

        {
            path: '/admin/user',
            component: () => import(/* webpackChunkName: "admin-user" */ './user'),
            children: [
                {
                    name: 'admin-user',
                    path: '',
                    component: () => import(/* webpackChunkName: "admin-user" */ './user/list'),
                },
                {
                    name: 'admin-user-add',
                    path: 'add',
                    component: () => import(/* webpackChunkName: "admin-user" */ './user/add'),
                },
                {
                    name: 'admin-user-edit',
                    path: ':userId/edit',
                    component: () => import(/* webpackChunkName: "admin-user" */ './user/edit'),
                    props: true,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_ADMIN',
            },
        },

        {
            path: '/admin/membership-year',
            component: () => import(/* webpackChunkName: "admin-membership-year" */ './membership_year'),
            children: [
                {
                    name: 'admin-membership-year',
                    path: '',
                    component: () => import(/* webpackChunkName: "admin-membership-year" */ './membership_year/list'),
                },
                {
                    name: 'admin-membership-year-add',
                    path: 'add',
                    component: () => import(/* webpackChunkName: "admin-membership-year" */ './membership_year/add'),
                },
                {
                    name: 'admin-membership-year-edit',
                    path: ':membershipYearId/edit',
                    component: () => import(/* webpackChunkName: "admin-membership-year" */ './membership_year/edit'),
                    props: true,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_ADMIN',
            },
        },

        {
            path: '/admin/event',
            component: () => import(/* webpackChunkName: "admin-event" */ './event'),
            children: [
                {
                    name: 'admin-event',
                    path: '',
                    component: () => import(/* webpackChunkName: "admin-event" */ './event/list'),
                    props: (route) => ({
                        startDate: route.query['start-date'],
                        endDate: route.query['end-date'],
                    }),
                },
                {
                    name: 'admin-event-view',
                    path: ':eventId/view',
                    component: () => import(/* webpackChunkName: "admin-event" */ './event/view'),
                    props: true,
                },
                {
                    name: 'admin-event-add',
                    path: 'add',
                    component: () => import(/* webpackChunkName: "admin-event" */ './event/add'),
                    props: (route) => ({ duplicateEventId: route.query['duplicate-event-id'] }),
                },
                {
                    name: 'admin-event-edit',
                    path: ':eventId/edit',
                    component: () => import(/* webpackChunkName: "admin-event" */ './event/edit'),
                    props: true,
                },
                {
                    name: 'admin-event-schedule',
                    path: ':eventId/schedule',
                    component: () => import(/* webpackChunkName: "admin-event" */ './event/schedule'),
                    props: true,
                },
                {
                    name: 'admin-event-groups',
                    path: ':eventId/groups',
                    component: () => import(/* webpackChunkName: "admin-event" */ './event/groups'),
                    props: true,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_USER',
            },
        },

        {
            path: '/admin/activity',
            component: () => import(/* webpackChunkName: "admin-activity" */ './activity'),
            children: [
                {
                    name: 'admin-activity',
                    path: '',
                    component: () => import(/* webpackChunkName: "admin-activity" */ './activity/list'),
                },
                {
                    name: 'admin-activity-add',
                    path: 'add',
                    component: () => import(/* webpackChunkName: "admin-activity" */ './activity/add'),
                },
                {
                    name: 'admin-activity-edit',
                    path: ':activityId/edit',
                    component: () => import(/* webpackChunkName: "admin-activity" */ './activity/edit'),
                    props: true,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_ADMIN',
            },
        },

        {
            path: '/admin/activity-year',
            // same component as activity
            component: () => import(/* webpackChunkName: "admin-activity" */ './activity'),
            children: [
                {
                    name: 'admin-activity-year-add',
                    path: 'add',
                    component: () => import(/* webpackChunkName: "admin-activity" */ './activity_year/add'),
                    props: (route) => ({ activityId: route.query['activity-id'] }),
                },
                {
                    name: 'admin-activity-year-edit',
                    path: ':activityYearId/edit',
                    component: () => import(/* webpackChunkName: "admin-activity" */ './activity_year/edit'),
                    props: true,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_ADMIN',
            },
        },

        {
            path: '/admin/activity-sub',
            // same component as activity
            component: () => import(/* webpackChunkName: "admin-activity" */ './activity'),
            children: [
                {
                    name: 'admin-activity-sub-add',
                    path: 'add',
                    component: () => import(/* webpackChunkName: "admin-activity" */ './activity_sub/add'),
                    props: (route) => ({ activityId: route.query['activity-id'] }),
                },
                {
                    name: 'admin-activity-sub-edit',
                    path: ':activityYearId/edit',
                    component: () => import(/* webpackChunkName: "admin-activity" */ './activity_sub/edit'),
                    props: true,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_ADMIN',
            },
        },

        {
            path: '/admin/product',
            component: () => import(/* webpackChunkName: "admin-product" */ './product'),
            children: [
                {
                    name: 'admin-product',
                    path: '',
                    component: () => import(/* webpackChunkName: "admin-product" */ './product/list'),
                },
                {
                    name: 'admin-product-add',
                    path: 'add',
                    component: () => import(/* webpackChunkName: "admin-product" */ './product/add'),
                },
                {
                    name: 'admin-product-change-price',
                    path: ':productId/change-price',
                    component: () => import(/* webpackChunkName: "admin-product" */ './product/change_price'),
                    props: true,
                },
                {
                    name: 'admin-product-change-name',
                    path: ':productId/change-name',
                    component: () => import(/* webpackChunkName: "admin-product" */ './product/change_name'),
                    props: true,
                },
            ],
            meta: {
                requiresAuth: true,
                role: 'ROLE_ADMIN',
            },
        },

        {
            name: 'admin-bank-deposits',
            path: '/admin/bank-deposits',
            component: () => import(/* webpackChunkName: "admin-product" */ './bank_deposits'),
            props: (route) => ({ initialPayoutId: route.query['payout-id'] }),
            meta: {
                requiresAuth: true,
                role: 'ROLE_ADMIN',
            },
        },

        {
            name: 'admin-cash-sales',
            path: '/admin/cash-sales',
            component: () => import(/* webpackChunkName: "admin-product" */ './cash_sales'),
            meta: {
                requiresAuth: true,
                role: 'ROLE_ADMIN',
            },
        },

        {
            path: '/admin/pattern-library',
            name: 'pattern-library',
            component: () => import(/* webpackChunkName: "admin-pattern_library" */ './pattern_library'),
            meta: {
                requiresAuth: true,
                role: 'ROLE_SUPER_ADMIN',
            },
        },

        {
            path: '*',
            name: '404',
            component: () => import(/* webpackChunkName: "admin-error" */ './error/404'),
        },
        {
            path: '*',
            name: '403',
            component: () => import(/* webpackChunkName: "admin-error" */ './error/403'),
        },
    ],

    scrollBehavior,
    parseQuery,
    stringifyQuery,
});

router.beforeEach( async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    if (requiresAuth) {
        if (!store.getters.loggedIn) {
            window.location = router.resolve({ name: 'login' }).href;

            return;
        }

        const waitForApollo = function () {
            return new Promise((resolve) => {
                (function waitingForApollo (){
                    if (router.app.$apolloProvider) {
                        return resolve();
                    }

                    setTimeout(waitingForApollo, 10);
                })();
            });
        };

        // check if Apollo is setup on the Vue instance
        await waitForApollo();

        // check to see if they're still authenticated
        const result = await router.app.$apollo.query({
            query: RouteQuery,
        });
        if (!result.data.Me) {
            window.location = router.resolve({ name: 'login' }).href;

            return;
        }
        // JS files have changed
        if (result.data.EntrypointIntegrity !== store.state.entrypointIntegrityHashes.admin) {
            if (result.data.EntrypointIntegrity && store.state.entrypointIntegrityHashes.admin) {
                window.location.reload();

                return;
            }
        }

        // find the first matched route that has a role
        const routeWithRole = to.matched.find(record => record.meta && record.meta.role);

        // this route requires auth, therefore check if they have the right role
        if (store.getters.hasRole(routeWithRole.meta.role)) {
            next();
        } else {
            next({ name: '403' });
        }
    } else {
        next();
    }
});

router.afterEach((to) => {
    logPageView(to);
});

export default router;
