var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    { attrs: { href: _vm._f("formatPhone")(_vm.phone, "RFC3966") } },
    [_vm._v(_vm._s(_vm._f("formatPhone")(_vm.phone)))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }