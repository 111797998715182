var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showButton
        ? _c(
            "button",
            {
              staticClass: "button",
              attrs: {
                type: "submit",
                disabled: _vm.isSaving || _vm.isSaved || _vm.disableButton,
              },
            },
            [
              _vm._t("default", function () {
                return [_vm._v("Save")]
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isSaving && !_vm.isSaved
        ? _vm._t("cancel", function () {
            return [
              _vm.cancelTo
                ? _c(
                    "router-link",
                    { staticClass: "form-action", attrs: { to: _vm.cancelTo } },
                    [
                      _vm._t("cancel", function () {
                        return [_vm._v("Cancel")]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.isSaving && !_vm.isSaved ? _vm._t("additional") : _vm._e(),
      _vm._v(" "),
      _vm.isSaving
        ? _c(
            "span",
            { staticClass: "ml-4 text-sm italic" },
            [
              _c("span", {
                staticClass: "loading",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _vm._t("saving", function () {
                return [_vm._v("Saving…")]
              }),
            ],
            2
          )
        : _vm.isSaved
        ? _c(
            "span",
            { staticClass: "ml-4 text-sm italic" },
            [
              _vm._t("saved", function () {
                return [_vm._v("Saved")]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }